.timeline {
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.timeline_row {
    width: 100%;

}

.send__container{
    display: flex;
    flex-direction: column;
    min-width: 120px;
    align-items: flex-start;
    /* justify-content: center; */
    text-align: center;
}

.lb__approve{
    background-color: white;
    color:black;
    width: 130px;
    padding: 2px;
}

.btn_Send{
    height: 150px;
    flex-grow: 1;
}

