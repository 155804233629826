.container {
    /*height: 100%;
   
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;*/ 
    width: 100%;

}

.header__Projects {
    width: 100%;
    background-color: #582A50;
    color: white;
    /*padding: 10px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    

}
.header__Projects h2{
    margin-left: 10px;
}

.change__view button{
    padding: 5px;
    margin: 5px;
}
.activeButton{
    background-color: #49454F;
    border-radius: 5px;
    color: white;
}
.container__projectListTable{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;
    overflow-y: auto;
    max-height: 80vh;
}
.container__projectList {
    /* width: 100%;
    display: flex;
    flex-wrap: wrap; */
    /*justify-content: flex-start;
    align-items: flex-start;*/
    /* padding: 10px;
    overflow-y: auto;
    max-height: 80vh; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.3333% - 20px), 1fr)); /* Mínimo 33.3333%, máximo 1 fracción del contenedor */
    grid-gap: 20px; /* Espacio entre las cards */
    max-height: 80vh;
    overflow-y: auto;
    padding: 10px;
    padding-right: 2rem;
}

.link {
    text-decoration: none;
    color: inherit;
    /*width: fit-content;
    height: fit-content;*/
    min-width: 400px;
    max-width: 400px;
}

/* Media query para 2 columnas en pantallas más pequeñas */
@media (max-width: 1400px) {
    .container__projectList {
        grid-template-columns: repeat(auto-fill, minmax(calc(50% - 20px), 1fr)); /* Mínimo 50%, máximo 1 fracción del contenedor */
    }
}

/* Media query para 1 columna en pantallas más pequeñas */
@media (max-width: 750px) {
    .container__projectList {
        grid-template-columns: 1fr;
    }
}

.filter__container {
  display: flex;
  flex-direction: row;
  /* Add other styles as needed */
}
