.container__recording {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    color: black;
    margin: 10px;
    border-radius: 10px;
    background-color: white;
}

.btn_stop {
    width: 50px;
    height: 50px;
    background-color: #952937;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.btn_playNarration {
    margin-right: 5px;
}
.btn_playNarrationDisabled {
    color: #ccc;
    margin-right: 5px;
    cursor: not-allowed;
}
.btn_playNarrationDisabled svg {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_timeline {
    width: 100%;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


}

.btn_playNarration svg {
    width: 50px;
    height: 50px;
    color: green;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg__mic {
    width: 50px;
    height: 50px;
    background-color: #952937;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.btn_recordDisabled {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.n_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.btn__n {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 10px 0px;
    flex-grow: 1;
    border: 1px solid white;
    padding: 5px 5px;
}

.div__btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 150px;
    align-items: start;*/
}

.btn__description {
    border-radius: 35px 0px 0px 35px;
}

.btn__comments {
    border-radius: 0px 35px 35px 0px;
}

.btn__selected {
    background-color: #C75E43;
}

.btn__unselected {
    background-color: transparent;
    transition: background-color 0.4s ease;
}

.btn__selected:hover {
    color: white;
}

.btn__unselected:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
}

.n_content {
    font-weight: normal;
    font-size: 28px;
    height: 100%;
    overflow-y: auto;
}

.edit_description {
    font-weight: normal;
    font-size: 28px;
    height: 100%;
    overflow-y: auto;
}

.textArea_editing_description {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    font-size: large;
    font-family: Arial, sans-serif;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    flex-grow: 1;
    resize: none;
    overflow-y: auto;
    transition: 300ms;
}

.textArea_description_db {
    width: 100%;
    padding: 10px;
    font-size: large;
    font-family: Arial, sans-serif;
    border: 0px solid #ccc;
    color: gray;
    background-color: #4e3d4b;
    border-radius: 4px;
    box-sizing: border-box;
    height: 170px;
    resize: none;
    overflow-y: auto;
    transition: 300ms;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;

}

.btn__withoutComment {
    border-radius: 35px;
}