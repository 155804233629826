.dates {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dates_label{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
}