.container {
    display: flex;
   /* border-bottom: 2px solid var(--text-color);*/
    width: 100%;
    flex-wrap: wrap;
    min-height: 50px;
    /* font-size: 1.5vw; 
    font-size: 20px;*/
}


.labelTime {

    color: var(--text-color);
    font-weight: 600;
    font-size: inherit;

}

.inputTime {
    background: transparent;
    color: var(--text-color);
    font-size: inherit;
    border: none;
    outline: none;
    font-weight: 500;
    text-align: center;
    flex-grow: 1;
    padding: 0;
    max-width: 50px;

}

.input__separator {
    font-size: inherit;
    color: white;
    font-weight: 600;
}

.container__separator {
    display: inline-flex;
    align-items: baseline;
}