.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 24px;
    max-width: 70%;
    max-height: 80%;
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
}

.tabs {
    border-right: 1px;
    border-color: white;
    background-color: var(--black);
    color: var(--text-color);
    height: 100%;
    min-width: 270px;
    max-width: 270px;
    height: 100%;
}

.tab_title {
    width: 100% !important;
    flex-direction: row !important;
    min-height: 70px !important;
    min-width: 270px !important;
    border-color: white !important;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    padding: 18px !important;
}

.container_contetTab {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.header_tab {
    display: flex;
    justify-content: center;
    max-width: 100%;
    height: 60px;
    align-items: center;
    background-color: #35323E;
    position: absolute;
    top: 0;
    left: 270px;
    right: 0;
    padding: 0 4rem;
}

.title_tab {
    text-align: center;
    color: #fff;
}

.content_tab {
    display: flex;
    justify-content: space-around;
}

.footer_tab {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    height: 60px;
    align-items: center;
    background-color: var(--black);
    position: absolute;
    bottom: 0;
    left: 270px;
    right: 0;
    padding: 0 4rem;
}
.tracking_content{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem;
}

.tracking_content__right{
    background-color: #28262E;
    width: 100%;
    border-radius: 10px;
    padding: 1rem;
}

.closeButton{
    position: absolute;
    right: 0;
    margin: 1rem
}