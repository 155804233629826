.button__send {
    max-width: 8.125rem;
    width: 100%;
    background: linear-gradient(180deg, #582A50 0%, #A93F90 100%);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    flex-grow: 1;
    
}
.button__send:hover {
    color: var(--secondary-color);
}

.button__send__disabled{
    max-width: 8.125rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background-color: #D3D3D3;
    color: #333333;
    flex-grow: 1;
    cursor: default;
}

