.container_comments{
    border-left: 1px solid #ccc;
    padding: 10px;
}
.comments_btn{
    background: #582A50;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.comments_btn svg{
    margin: .6rem;
    color: #fff;
    font-size: 2rem;
}
.comments_btn:hover{
    background: var(--orange);
}
.comments_qc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .6rem 1rem ;
    border-left: 1px solid #ccc;
    border-radius: 5px;
}
.header_comments{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
}
.textarea{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 1rem;
    margin-top: 1rem ;
    font-size: 1.2rem;
    background-color: #582A50;
    color: white;
    overflow-y: auto;
    resize: none;
}