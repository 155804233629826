.tabPanel {
    background: #35323E;
    width: 100%;
    height: 100%;
    overflow: auto;
    height: calc(100% - 120px);
    margin-top: 60px;
    padding: 0 !important;
}

.tabPanelGuide {
    background: #35323E;
    width: 100%;
    height: 100%;
    overflow: auto;
    height: 100%;
    padding: 0 !important;
}

