.select{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.range{
    margin:  10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.message_check{
    margin-top: 5px;
    color: #fff;
    font-size: .9rem;
    font-family: 'Roboto', sans-serif;
}