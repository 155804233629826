.actions {
    display: flex;
    /*justify-content: flex-start;*/
    align-items: center;


}

.dialog {
    min-width: 800px;

}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: var(--text-color);


}

/*button:hover {
    color: var(--secondary-color);


}*/

.button__add {
    margin-left: 50px;
}
.button:disabled svg{
    color: #cccccc75;
    cursor: not-allowed;

}