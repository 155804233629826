.container__TextLabel {
    display: flex;
    flex-direction: column;
    height: 40%;
    color: white;
}

.title__TextField {
    font-size: 1.5rem;
    font-weight: 600;
    /*width: 100%;*/
    display: flex;
    align-items: center;
}

.label__TextField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
    flex-wrap: wrap;

}

.textArea_description {
    width: 100%;
    padding: 10px;
    font-size: 28px;
    font-family: Arial, sans-serif;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 100%;
    resize: none;
    overflow-y: auto;

}


.textArea_description:disabled {
    color: white !important;
}

.container__descriptioTextFiel {
    /*height: 100%;*/
    position: relative;
    flex-grow: 1;
}

.span__characterCount {
    font-size: 1rem;
    font-weight: 600;
}

.container__count {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    margin: .5rem .7rem;
    color: black;
    border-radius: 50%;
    border: .3rem solid;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__textCount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}