@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;

}

:root {
  --primary-color: #3f51b5;
  --secondary-color: #FFC700;
  --text-color: #fff;
  --background-color: #28262E;

  --black: #241D26;
  --violet: #3D1C47;
  --murrey: #8D1B4E;
  --fandango: #A93F90;
  --orange: #f46036;
  --celeste: #12b4b8;
  --azul-lapis: #406b8e;
  --dark-purple: #090020;
}

.wavesurfer-handle-start, .wavesurfer-handle-end {
  width: 10px !important;
  background-color: rgb(0 0 0 / 50%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.wavesurfer-handle span {
  transform: scaleY(2.5);
  pointer-events: none;
}

.MuiDialogContent-root {
  min-width: 500px;
}

.MuiBackdrop-root {
  /* opacity: 0 !important; */
}

.loop {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #fff;
  z-index: 100;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.file-upload label {
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  padding: 10px 15px;
  /* border-radius: 5px; */
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload span {
  margin-left: 10px;
}

.container_srttocsv {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.container__button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: white;
  background-color: var(--orange);
  padding: 20px;
  border-radius: 10px;
  min-width: 500px;
}

.container__button:hover {
  background-color: var(--celeste);
  color: #090020;

}

.container_download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  color: white;
  background-color: #090020;
  padding: 20px;
  width: 100%;
  height: 100%;
}

/*.hjYtIJ {
  height: 60px !important;
}*/

.progressModal div .MuiCircularProgress-root {
  width: 300px !important;
  height: 300px !important;
}

.sliderDate .MuiSlider-thumb {
  position: absolute !important;
  width: 5px !important;
  height: 20px !important;
  box-sizing: border-box !important;
  border-radius: 0% !important;
  outline: 0 !important;
  background-color: currentColor !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  -webkit-transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  color: #fff !important;
}

.MuiPaper-root {
  max-width: max-content !important;
}
.GuideStepper{
  max-width: none !important;
}

.MuiStep-horizontal, .MuiStep-alternativeLabel {
  overflow: visible !important;
}

.MuiAutocomplete-listbox {
  z-index: 9999 !important;
}

iframe {
  display: none;
}

/* TODO: general style  background modal  */
.MuiDialog-container.MuiDialog-scrollPaper {
  background: rgba(0, 0, 0, 0.733) !important;
}

/* scroll */
::-webkit-scrollbar {
  width: 10px;
  /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-track {
  background: var(--black);
  /* Color del fondo de la barra de desplazamiento */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--azul-lapis);
  /* Color del fondo de la barra de desplazamiento */
  border-radius: 10px;
}

/* Estilos para la barra de desplazamiento horizontal (si es necesario) */
::-webkit-scrollbar-horizontal {
  height: 10px;
  /* Alto de la barra de desplazamiento horizontal */
}

::-webkit-scrollbar-thumb:horizontal {
  background: var(--azul-lapis);
  /* Color del fondo de la barra de desplazamiento */


}

label.sc-aXZVg.guZdik {
  min-width: auto !important;
  border-radius: 0px !important;
  width: 100%
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 9px;
  height: 9px;
  background: #fff;
  color: #fff;
  box-shadow:
    -18px -9px, 0 -9px, 18px -9px,
    -18px 0, 18px 0,
    -18px 9px, 0 9px, 18px 9px,
    -18px 18px, 0 18px, 18px 18px;
  animation: l30 2s infinite;
  position: absolute;
  bottom: 5rem;
}

@keyframes l30 {
  10% {
    box-shadow:
      -18px -9px, 0 -9px, 18px -9px,
      -18px 0, 18px 0,
      -18px 9px 0 2px, 0 9px, 18px 9px 0 2px,
      -18px 18px, 0 18px, 18px 18px;
  }

  20% {
    box-shadow:
      -18px -9px, 0 -9px, 18px -9px,
      -18px 0, 18px 0,
      -18px 9px, 0 9px, 18px 9px 0 2px,
      -18px 18px 0 2px, 0 18px, 18px 18px;
  }

  30% {
    box-shadow:
      -18px -9px, 0 -9px, 18px -9px,
      -18px 0 0 2px, 18px 0,
      -18px 9px, 0 9px 0 2px, 18px 9px,
      -18px 18px 0 2px, 0 18px, 18px 18px 0 2px;
  }

  40% {
    box-shadow:
      -18px -9px, 0 -9px, 18px -9px,
      -18px 0 0 2px, 18px 0 0 2px,
      -18px 9px, 0 9px, 18px 9px,
      -18px 18px, 0 18px, 18px 18px 0 2px;
  }

  50% {
    box-shadow:
      -18px -9px, 0 -9px, 18px -9px,
      -18px 0, 18px 0 0 2px,
      -18px 9px 0 2px, 0 9px, 18px 9px,
      -18px 18px, 0 18px, 18px 18px;
  }

  60% {
    box-shadow:
      -18px -9px, 0 -9px 0 2px, 18px -9px,
      -18px 0, 18px 0,
      -18px 9px 0 2px, 0 9px, 18px 9px,
      -18px 18px 0 2px, 0 18px, 18px 18px;
  }

  70% {
    box-shadow:
      -18px -9px, 0 -9px 0 2px, 18px -9px 0 2px,
      -18px 0, 18px 0,
      -18px 9px, 0 9px, 18px 9px,
      -18px 18px 0 2px, 0 18px, 18px 18px;
  }

  80% {
    box-shadow:
      -18px -9px 0 2px, 0 -9px, 18px -9px,
      -18px 0, 18px 0,
      -18px 9px, 0 9px, 18px 9px,
      -18px 18px, 0 18px, 18px 18px 0 2px;
  }

  90% {
    box-shadow:
      -18px -9px 0 2px, 0 -9px, 18px -9px,
      -18px 0, 18px 0,
      -18px 9px, 0 9px 0 2px, 18px 9px,
      -18px 18px, 0 18px, 18px 18px 0 2px;
  }
}

.card-voice-over{
  padding: 0px !important;
}

label[for="video_segmentation_voice_over"],label[for="caption_segmentation_voice_over"] {
  color: blue;
  font-weight: bold;
  height: 100px;
  margin:.5rem;
}

.card__uploadFile{
  border-radius: 10px !important;
  margin: 0 5rem;
  width: 25vw;
  max-width: 500px !important;
  background-color:#121212be !important;
}
.card__joinSRT{
  border-radius: 10px !important;
  margin-right: 2rem;
  min-width: 500px !important;
  background-color:#121212be !important;
}