.container {
    display: flex;
    flex-grow: 1;
   /* width: 100%;
    height: 100%;*/
}

.container_stagging {

    height: 100%;
    max-width: 21.25rem;
}

.container_cards {
    width: 80%;
    height: 100%;
}
html{overflow-y:auto}