.container {
    height: 100%;
}

.container_Vertical {
    display: flex;
    flex-direction: column;
    /*align-items: center;
    height: 100%;
    height: 100%;
    width: 25%;*/
    background-color: var(--black);
    min-width: 275px;
}

.container__ongoingProjects {
    display: flex;
    flex-direction: column;
   /* width: 100%;
    padding: 10px;
    height: 100%;*/
    flex-grow: 1;
    justify-content: space-between;

}

.header__ongoingProjects {
    /*display: flex;
    align-items: flex-start;

    width: 100%;*/
    color: var(--text-color);
    padding: 10px;

}

.header__ongoingProjects h2{
    text-align: center;

}

.container__projectList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.card_project {
    width: 100%;
    max-width: 500px;
}

.card__container {
    display: flex;
   /* width: 100%;
    justify-content: space-between;

    border-radius: 10px;
    
    padding: 0 1rem;*/
    margin: 10px;
    cursor: pointer;
}
.card__containerActive{
    display: flex;
    /* width: 100%;
     justify-content: space-between;
 
     border-radius: 10px;
     
     padding: 0 1rem;*/
     margin: 10px;
     cursor: pointer;
     box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
     border-radius: 10px;
 
}
.container_name {
    background-color: #4a0d28;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;

}

.container_name h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: white;
    /*padding: 17px 0 0 15px;*/
    margin: 10px;
}

.container_count {
    background-color: #8D1B4E;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
    width: 100px;
    align-items: center;
}

.container_count span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: white;
    /*padding: 17px 15px;*/
}

.btn_create {
    background-color: var(--orange);
    border-radius: 10px;

    /*display: flex;
    justify-content: center;
    align-items: center;*/
    margin: 3rem 2rem;
    padding: 1rem 0;
    font-size: 1.375rem;
    font-weight: 700;


}