.qc_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.div__btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn__qc {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 10px 0px;
    flex-grow: 1;
    border: 1px solid white;
    padding: 5px 5px;
}

.btn__description {
    border-radius: 35px 0px 0px 35px;
}

.btn__comments {
    border-radius: 0px 35px 35px 0px;
}

.btn__comments_only {
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
}

.btn__preview {
    padding-top: 8px;
}

.btn__selected {
    background-color: #C75E43;
}

.btn__unselected {
    background-color: transparent;
    transition: background-color 0.4s ease;
}

.btn__selected:hover {
    color: white;
}

.btn__unselected:hover {
    background-color: rgba(255, 255, 255, 0.5);
    color: white;
}

.qc_content {
    position: relative;
    font-weight: normal;
    font-size: 28px;
    height: 150px;
    overflow: auto;
    width: 100%;
}

.edit_description {
    font-weight: normal;
    font-size: 28px;
    height: 150px;
    overflow: auto;
}

.qc__title {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.qc__title2 {
    font-weight: normal;
    padding-bottom: 10px;
}

.textArea_editing_description {
    position: relative;
    width: 100%;
    padding: 10px;
    font-size: large;
    font-family: Arial, sans-serif;
    border: 0px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 150px;
    resize: none;
    overflow-y: auto;
    transition: 300ms;
}

.textArea_description {
    width: 100%;
    padding: 10px;
    font-size: large;
    font-family: Arial, sans-serif;
    border: 0px solid #ccc;
    color: white;
    background-color: #582A50;
    border-radius: 4px;
    box-sizing: border-box;
    height: 170px;
    resize: none;
    overflow-y: auto;
    transition: 300ms;
}

.textArea_description::placeholder {
    color: white;
    font-weight: normal;
    font-size: large;
    transition: 300ms;
}


.textArea_description_db {
    width: 100%;
    padding: 10px;
    font-size: large;
    font-family: Arial, sans-serif;
    border: 0px solid #ccc;
    color: gray;
    background-color: #4e3d4b;
    border-radius: 4px;
    box-sizing: border-box;
    height: 170px;
    resize: none;
    overflow-y: auto;
    transition: 300ms;
}

.textArea_description_db::placeholder {
    color: rgb(163, 163, 163);
    font-weight: normal;
    font-size: large;
    transition: 300ms;
}

.button:disabled svg {
    color: #cccccc75;
    cursor: not-allowed;

}

.container__count {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    margin: .5rem .7rem;
    color: black;
    border-radius: 50%;
    border: .3rem solid;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.span__characterCount {
    font-size: 1rem;
    font-weight: 600;
}