.btn {

    color: var(--background-color);
}

.btn_stop {
    background-color: rgb(128, 2, 2);
    border-radius: 50%;
    animation: cssAnimation_0 .5s infinite linear;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}


@keyframes cssAnimation_0 {
    from {
        background-color: rgb(128, 2, 2);
    }

    to {
        background-color: rgba(255, 255, 255, 0);
    }
}


.btn_record {
    background-color: #952937;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.btn_record:hover {
    color: #952937;
    background-color: white;
    border: 1px solid #952937;

}

.btn_play {
    color: white;

    border-radius: 50%;
    margin-left: 1rem;
}

.btn_play:hover {
    color: #0e9815;
}

.btn_play svg {
    font-size: 3.5rem !important;
}

.btn_playTest {
    margin-left: 0;
    margin-right: 16px;
    min-width: 50px

}

.container__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    align-items: center;
}

.seconds_container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -70px;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--background-color);
    color: white;
    font-size: 2rem;
    font-weight: bold;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

}


.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
}

.container_timeline {
    width: 100%;
}

.container_testRecord {
    margin: 10px 0;
}

.test_text {
    width: 100%;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
}

.container_record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.btn_record_test {
    margin-right: 10px;
    min-width: 50px
}

.container_timeline_test {
    width: 85%;

}