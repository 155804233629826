.container__recording {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    color: black;
    margin: 10px;
    border-radius: 10px;
    background-color: white;
}

.btn_playNarration {
    margin-right: 5px;
}

.container_timeline {
    width: 100%;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;


}

.btn_playNarration svg {
    width: 50px;
    height: 50px;
    color: green;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bg__mic {
    width: 50px;
    height: 50px;
    background-color: #952937;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.btn_recordDisabled {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}

.c_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
}

.div__btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    /*height: 150px;
    align-items: start;*/
}

.btn__description {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 10px 0px;
    flex-grow: 1;
    border: 1px solid white;
    padding: 5px 5px;
    border-radius: 35px;
    background-color: #C75E43;
}

.n_content {
    font-weight: normal;
    font-size: 28px;
    height: 50%;
    overflow-y: auto;
}

.textArea_description {
    width: 100%;
    padding: 10px;
    font-size: large;
    font-family: Arial, sans-serif;
    border: 0px solid #ccc;
    color: white;
    background-color: #582A50;
    border-radius: 4px;
    box-sizing: border-box;
    height: 100%;
    resize: none;
    overflow-y: auto;
    transition: 300ms;
}

.textArea_description::placeholder {
    color:white;
    font-weight: normal;
    font-size:large;
    transition: 300ms;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}



.clientComments__title{
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.clientComments__title2{
   font-weight: normal;
   padding-bottom: 10px;
}