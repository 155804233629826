.container__descriptorVideo {
    display: flex;
    height: 0;
    flex: 1 0 auto;
}



.container__descriptor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*width: 100%;
    height: 100%;*/
    flex: 1 0 auto;
}

.container__timeLine {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.timeline {
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.timeline_row {
    width: 100%;

}
.container__commentsQC{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
}