.container__viewer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
 
}


.videoContainer{
    width: 50%;
    border-radius: 10px;
    margin: 1rem;
    
}
.container__videoControls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 2rem;
    /* width: 100%; */
    background: #332D36;
    border-radius: 10px;
    display: flex;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    /*justify-content: space-between;*/
    min-height: 50px;
    padding: 0px 5px;
}

.container__videoControls button {
    background-color: transparent;
    border: none;
    color: white;
    margin: 0px 5px;
}

.container__videoControls button:hover {
    color: var(--orange);
    cursor: pointer;
}