.label {
    width: fit-content;
    margin-bottom: 8px;
    flex-grow: 1;
    max-width: 450px;
    color: white;
}
.labelInput {
    width: fit-content;
    margin: 4px 0;
    flex-grow: 1;
    max-width: 450px;
    display: block;
    color: white;
}

.labelVideo{
    width: fit-content;
    margin: 4px 0;
    flex-grow: 1;
    max-width: 450px;
    display: block;
    color: white;
    font-size: .8rem;

}

.placeholder {
    width: 100%;
    height: 100%;
    color: #ccc;
}

.button__add {
    background-color: var(--background-color);
    color: var(--text-color);
    border: none;
}

.button__add:hover {
    color: var(--secondary-color);
    border: none;
    cursor: pointer;
}

.container__narrators {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 8px;
}

.container__forms {
    /*display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;*/
    margin: auto;
}

.container__form {
    /*padding: 0 16px;
    width: 100%;
        max-width: 33.3%;
    height: 100%;*/
    min-width: 35ch;
    overflow-y: auto;
    /* height: 450px; */
}

/*.container__inputNarrator {
    display: flex;
    flex-direction: column;
}*/

.title_forms {
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1.875rem;
}

.loading {
    position: fixed;
    bottom: 70px;
    right: 70px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-color);
    border-radius: 10px;
    padding: 1rem;

}

.loading h4::before {
    content: '✓ ';
    color: #8EC36A;
}

.loading_exit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.btn_Arrow {
    background: #3D1C47;
    border-radius: 10px;
    padding: 10px;
    /*height: fit-content;*/
    margin: auto;
    /*display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;*/
}

.datepicker div div{
    height: 50px;
    width: 50px;    
}

.label_spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 450px;
    width: 100%;
}
.optionsClient{
    display: flex;
    flex-direction: column;
    max-width: 450px;
    margin-left: 2rem;
}

.linkSegmentVoiceOver {
    margin-left: 0.5rem;
    width: 60px;
    height: 50px;
    background: #582a50;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none; /* Añadido para quitar el subrayado de los enlaces */
  }
  .linkSegmentVoiceOver:hover {
    background: #3d1c47;
  }
  
  .iconSegmentVoiceOver {
    color: white;
    width: 35px;
    height: 35px;
  }
  