.volume {
    max-width: 250px;
    color: white;
    width: 100%;
}

.captions {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    width: 80%;
    /* max-width: 250px; */
    position: absolute;
    text-align: center;
    bottom: .5rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 100;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    /* visibility: hidden; */

}

.controls__advance {
    /*width: 40%;*/
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    position: relative;
}

.isLooping {
    color: green;
}

.container__video {
    width: 100%;
    border-right: 1px solid #ccc;
    /*height: max-content;*/
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    justify-content: center;
    /*position: relative;*/

}

.container__video video {
   
    /* width: 100%; */
    /* height: 100%; */
    /* object-fit: cover;  */
    border-radius: 10px;
    /*position: relative;*/
    width: -webkit-fill-available;
    height: 100%;
}

.input__rangeTime {
    width: 100%;
}

.input__labelsRangeTime {
    display: flex;
    justify-content: space-between;
    min-height: 20px;
    /*align-items: center;
    width: 100%;
    padding: 0.5rem 0;*/


}

.input__labelsRangeTime span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /*line-height: 12px;*/
    font-size: 1.2rem;
    color: white;
}

.container__videoControls {
    /* width: 100%; */
    background: #332D36;
    border-radius: 10px;
    display: flex;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
    /*justify-content: space-between;*/
    min-height: 50px;
    padding: 0px 5px;
}

.container__videoControls button {
    background-color: transparent;
    border: none;
    color: white;
    margin: 0px 5px;
}

.container__videoControls button:hover {
    color: var(--orange);
    cursor: pointer;
}