.container__clientActions {
    width: 40%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
}

.select__segment {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--orange);
}

.select__segment img {
    width: 80px;
    margin-bottom: 1rem;
    object-fit: cover;
}
.container__clientActions__region{
    width: 100%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    /*margin-bottom: 1rem;*/
    height: 100%;
}