.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  background-image: url('/public/assets/img/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-color: var(--black);
  color: var(--text-color);
  
}