.container__card {
    /*display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;*/
     background-color: #90428391;
    /* border-radius: 10px; 
    padding: 10px;
    margin: 10px;
    width: 400px; */
    /* max-width: 500px; */
    /* min-height: 200px;
    max-height: 400px; */
    /* height: 100%; */
    /* display: flex;
    flex-direction: column; */
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
    margin: 10px; 
}
.input__name{
    width: 100%;
    height: 100%;
    border: white 1px solid;
    background-color: transparent;
    color: white;
    font-size: inherit;
    font-weight: bold;
    /* outline: none; */
    padding: 5px 0 5px 5px ;
}
.input__name:focus{
    outline: none;
    border: white 1px solid;
    background-color: transparent;
    color: white;
    font-size: inherit;
    font-weight: bold;
}
.button svg {
    margin-right: 15px;
}
/* btn disbaled color white */
.button:disabled svg{
    color: #696969
}
.container__cardPM {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(36, 29, 38, 0.8);
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 400px; */
    /*max-width: 500px;*/
    /*min-height: 200px;*/
    /*max-height: fit-content;*/
    /* color: white; */
    background-color: rgba(36,29,38,.8);
    border-radius: 10px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: 100%;
    margin: 10px; /* Ajusta este valor según el margen que desees entre las cards */
    
}


.container__header {
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    width: 100%;
    /*height: 100%;*/
    padding: 10px;
    
    
}

.container__header h3{
    word-break: break-all;
    padding: 20px 0;
}

.container__actions{
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    align-items: center;
    height: 50px;
}

.label__state {
    background-color: rgb(169, 63, 144);
    border-radius: 20px;
    padding: 5px 8px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.label__stateTrueSegments{
    background-color: rgb(2, 179, 2);
    border-radius: 50%;
    padding: 3px 9px ;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    
}
.label__stateFalseSegments{
    background-color: rgb(255, 0, 0);
    border-radius: 50%;
    padding: 3px 9px ;
    color: black;
    font-weight: bold;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.label__stateProgressSegments{
    background-color: rgb(213, 174, 0);
    border-radius: 50%;
    padding: 3px 9px ;
    color: black;
    font-weight: bold;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.container__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    /*height: 100%;*/
    padding: 10px;
}

.container__main p:first-child {
    margin-bottom: 20px;
    padding-right: 5px;
    max-height: 100px;
    overflow-y: auto;
}
.container__headerTitle{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;


}

.container__main h3{
    width: inherit;
    min-height: 60px;
}

.container__main p{
    overflow-y: auto;
    max-height: 100px;
    padding-right: 10px;
}

.container__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   /* height: 100%;*/
    padding: 10px;
}

.container__footer span {
    background-color: var(--orange);
    border-radius: 10px;
    padding: .7rem 8px;
    color: white;
    font-weight: bold;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 70%;
    height: 100%;
    text-align: center;
    color: white;

}
.container__actions{
    /*display: flex;
    justify-content: space-between;
     width: 100%; 
    align-items: center;
    margin-top: 10px;*/
    align-self: self-end;
}
.link{
    text-decoration: none;
    color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.icon{
    width: 28px;
    height: 28px;
    margin-right: 10px;
    padding: 2px;
    
}
.icon:hover{
    background-color: var(--orange);
    border-radius: 10%;
}
/* hover de icono que este en disabled */
.icon:disabled:hover{
    background-color: transparent;
    border-radius: 10%;
    cursor: not-allowed;
}
