.header{
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding:.5rem 1rem;
    margin: 1rem 0;
    text-align: center;
    font-weight: 700;
}

.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;    
    height: 80vh;
}

.container__card{
    display: flex;
    flex-direction: column;
    align-self: center;
    height: min-content;
    min-width: 500px;
}
.btn_create {
    background-color: var(--orange);
    border-radius: 10px;
    margin: .5rem auto;
    padding: 1rem ;
    font-size: 1.375rem;
    font-weight: 700;
    width: 90%
}

.btn_create:disabled {
    background-color: #eaeaea;
    color: #a8a8a8;
}
.container__stepper{
    display: flex;
    flex-direction: column;
    align-self: center;
    background: linear-gradient(-90deg, rgba(88, 42, 80, 0) 0%, #582A50 73.44%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    min-width: 60vw;
    height: min-content;
    padding: 1rem;
}

.header_cardSRT{
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    padding:.5rem 1rem;
    margin: 1rem 0;
}

.labelVideo{
    width: fit-content;
    margin: 5px 1rem;
    flex-grow: 1;
    max-width: 450px;
    display: block;
    color: white;
    font-size: .8rem;
}
