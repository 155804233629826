.container_createForm {
    display: flex;
    /*align-items: flex-end;
    justify-content: center;*/
    background-color: rgba(36, 29, 38, 0.8);
    border-radius: 10px;
    margin: 2rem 2rem 4rem 2rem;
   /* height: 80%;
    padding: 2rem;*/
    overflow-y: auto;
    flex-grow: 1;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.header__Projects {
    width: 100%;
    background-color: #582A50;
    color: white;
    padding: 10px;

}