.container__logo {
   /* display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;*/
    margin: 5px 0 0 5px;

}

.container__logoCompany {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.container__navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*flex-wrap: wrap;*/
    background-color: var(--black);
    font-family: 'Roboto'sans-serif;
}

.container__projectName {

    font-style: normal;
    font-weight: 700;
    font-size: 0.6rem;
    line-height: 32px;
    color: white;
}

.container__user {
    display: flex;
    align-items: center;
    justify-content: center;

}

.container__user__name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 1.2rem;
    line-height: 32px;
    color: white;
    margin-right: 1rem;
}

.container__user__name span:nth-child(2) {
    font-weight: 700;
    margin-right: .5rem;
}
.content_progress{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    margin: 1rem 0 0 5px;
    width: 100%;
    /* min-width: 300px; */
}
.content_text{
    margin-left: 2rem;
    margin-right: 2rem;
    width: 50%;
    min-width: 300px;
}

.content_text_shortcuts{
    /* margin-left: 2rem;
    margin-right: 2rem;
    width: 50%; */
    min-width: 300px;
    & h5 {
        font-weight: lighter;
    }
}
.content_textSpan{
    font-size: 1.4rem;
    font-weight: 500;
    display: block;
    text-align: end;
    font-family: 'Roboto', sans-serif;
}

.text_blue{
    color:#12B4B8
}
.text_orange{
    color: #C75E43;
}
.text_green{
    color: #4CAF50;
}
.content_guideline{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    margin: 1rem 0 0 5px;
    width: 100%;
    /* min-width: 300px; */
    color: white;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;

}

.badge{
    position: absolute;
    background: var(--celeste);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 3px;
}

.row_down{
    font-size: 13px;
    color: #000;
}