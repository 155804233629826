.container__actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 70px;
    width: 100%;
    height: 100%;
    background-color: var(--black);


}

.actions {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 116px;
}

/* .actions button{
    display: flex;
    flex: 1 0 0px;
} */

.btn:hover svg {
    color: var(--orange);
}