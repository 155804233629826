.container__login {
    display: flex;
    /*width: 100%;
    justify-content: end;
    align-items: center;
    height: 100%;*/
    flex-grow: 1;
}

.container {
    display: flex;
    flex-direction: column;
    align-self: center;
    /*justify-content: center;
    align-items: flex-end;*/
    background: linear-gradient(180deg, rgba(88, 42, 80, 0) 0%, #582A50 73.44%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 50px 0px 0px 50px;
    min-width: 613px;

    /*width: min-content;*/
    height: min-content;

}

.header {
    /*display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    width: 100%;*/
    align-self: center;

}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
   /* justify-content: center;
    padding: 0 2rem;
    width: 100%;
    height: 100%;
    margin-bottom: 4.875rem;*/
    padding: 0 4rem;
    margin-bottom: 10px;
}

/*.container__formGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

}*/

.formGroup {
    /*display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;*/
    margin-top: 10px;
}

.formGroup__label {
    font-family: 'Atkinson Hyperlegible';
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 42px;
    color: white;
}

.formGroup__input {
    background: linear-gradient(180deg, rgba(61, 28, 71, 0.4) 0%, rgba(244, 96, 54, 0.1) 100%);
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 3px 3px 3px rgba(0, 0, 0, 0.5);*/
    border-radius: 12px;
    width: 100%;
    min-height: 4.3125rem;
    border: 1px solid black;
    font-size: 1.5rem;
    padding-left: 1rem;
    color: white;

}

.formGroup__checkbox {
    width: 30px;
    height: 30px;
    transform: scale(0.7);
    margin-right: 10px;
}

.remember {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 1.5rem;
}

.formGroup__checkboxLabel {
    font-family: 'Atkinson Hyperlegible';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: white
}

.btnLogin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 27px;
    gap: 12px;
    background: var(--orange);
    box-shadow: 0px 1.5px 3px rgba(105, 81, 255, 0.05);
    border-radius: 12px;
    font-family: 'Atkinson Hyperlegible';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    width: 100%;

}

.btnLogin:hover {
    color: var(--azul-lapis);
}

.container__btnLogin {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.save {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.forgotPassword {
    font-family: 'Atkinson Hyperlegible';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: white;
    margin-top: .5rem;
    text-decoration: none;

}

.container__company {
    /*display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 100%;*/
    margin-left: 30px;
    margin-bottom: 50px;
    margin-top: auto;
    flex-grow: 1;
}

.label__company {

    font-family: 'Atkinson Hyperlegible';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    color: white;
}

/*.logo__company {
    margin-top: -16px;
}*/