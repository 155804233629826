.container_db{
    position: relative;
    
    /*padding: 10px 0;*/
    display: flex;
    align-items: center;
    justify-content: center;
    /*max-height: inherit;
    height: 56px;*/
    border: 1.5px solid white;
    height: 100%;
    min-width: 40px;
   /* margin-right: 2px;*/
}

.label_db{
    /* max-width: 50px; */
    min-width: 40px;
    /* height: 100%; */
    white-space: nowrap;
    text-align: center;
    z-index: 10001;
    font-weight: bold;
    font-size: small;

}
.bar_db{
    position: absolute;
    bottom: 0;
    
    /* width: 20px !important; */
    
    transition: height 0s ease-in-out;


}
.label_db_horizontal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.label_db_horizontal span{
    font-size: small;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: white;
    margin: 0 5px;
}
.label_scaleDB{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*height: 100%;*/
    padding-right: 10px;
    overflow: initial

}

.label_scaleDB span{
    font-size: small;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: white;
    margin: 0 5px;
    text-align: left;
    white-space: nowrap;
}