.container {
    background-color: var(--black);
    width: 100%;
    height: min-content;
    color: white;
    position: fixed;
    bottom: 0;
}

.container__footer {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 5px 0 5px 0;
    /*flex-wrap: wrap;
    padding: .5rem 0;*/
}

.separator {
    margin: 0 .6rem;
}

.copyright {
    font-size: 14px;
}

.rights {
    font-size: 16px;
    font-weight: bold;
}

.rights p {
    margin: 0;
}